import React, { Component } from "react";
import './Navbar.css'
import logo from "../Images/logo.png";

class Navbar extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <nav className="NavbarItems">
                <h1 className="navbar-logo">
                    <img className="navbar-logo" src={logo} />
                </h1>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fa fa-times' : 'fa fa-bars'}/>
                </div>
                {/*<ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>*/}
                {/*    {MenuItems.map((item, index) => {*/}
                {/*        return (*/}
                {/*            <li key={index}>*/}
                {/*                <a className={item.cName} href={item.url}>*/}
                {/*                {item.title}*/}
                {/*                </a>*/}
                {/*            </li>*/}
                {/*        )*/}
                {/*        })}*/}
                {/*</ul>*/}
            </nav>
        )
    }
}

export default Navbar