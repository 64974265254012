import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import './Lists.css'


export default class SportList extends React.Component {
    state = {
        items: []
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        const items = this.props.dataList.sort((a, b) => {
            let fa = a.studio.toLowerCase(),
                fb = b.studio.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        if (items) {
            return (
                <TableContainer variant="outlined">
                    <Table className="table">
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell className="tableHeader">Sport</TableCell>
                                <TableCell className="tableHeader">Title</TableCell>
                                <TableCell className="tableHeader">Winner</TableCell>
                                <TableCell className="tableHeader">Opponent</TableCell>
                                <TableCell className="tableHeader">MVP</TableCell>
                                <TableCell className="tableHeader">Coach</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.uuid}>
                                    <TableCell className = "tableCell">{item.sport}</TableCell>
                                    <TableCell className = "tableCell">{item.studio}</TableCell>
                                    <TableCell className = "tableCell">{item.team}</TableCell>
                                    <TableCell className = "tableCell">{item.opponent}</TableCell>
                                    <TableCell className = "tableCell">{item.mvp}</TableCell>
                                    <TableCell className = "tableCell">{item.coach}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        else {
            return null
        }
    }
}
