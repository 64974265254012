import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

export default class GameList extends React.Component {

    componentDidMount() {
 
    }

    componentDidUpdate() {

    }

    render() {
        const items = this.props.dataList.sort((a, b) => {
            let fa = a.title.toLowerCase(),
                fb = b.title.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        if (items) {
            return (
                <TableContainer variant="outlined">
                    <Table className="table">
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Publisher</TableCell>
                                <TableCell>Platform</TableCell>
                                <TableCell>Genre</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.uuid}>
                                    <TableCell className = "tableCell">{item.title}</TableCell>
                                    <TableCell className = "tableCell">{item.studio}</TableCell>
                                    <TableCell className = "tableCell">{item.artist}</TableCell>
                                    <TableCell className = "tableCell">{item.Main_Genre}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        else {
            return null
        }
    }
}
