import React, {Component} from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import './Homescreen.css'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import MovieList from './MovieList.js';
import MusicList from './MusicList.js';
import GamesList from './GamesList.js';
import SportList from './SportList.js';
import {FaBasketballBall, FaMusic} from "react-icons/fa";
import { FaFilm } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { connect } from 'react-redux';
import * as actions from '../actions/actions';

class Homescreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            value: this.props.sliderYear,
            date: this.props.sliderYear,
            apiDate: this.props.sliderYear
      }

    }

    componentDidMount() {
        //update all data list on initial load
        this.props.dispatch(actions.fetchYearData(this.state.value))
    }

    handleChangeStart = () => {
        console.log('Change event started')
    };

    handleChange = value => {
        this.setState({value: value})
    };

    handleDateChange = event => {
        this.setState({value: this.state.date})
    }

    handleChangeComplete = () => {
        console.log('Change event completed')

        //update slider year
        this.props.dispatch(actions.changeSliderYear(this.state.value))

        //update all list data
        this.props.dispatch(actions.fetchYearData(this.state.value))
    }

    render() {
        const { value } = this.state;
        const { movieList, musicList, gameList, sportList } = this.props;

        return (
            <div className='tabs'>
                <div className='caption'>
                    <h3> Select a year below to take a trip to the past!</h3>
                    <div className='sliderWrap'>
                        <div className='dateSelector'>
                            <div className = "dateDisplay">
                                {value}
                             </div>
                        </div>
                        <div className='slider'>
                            <Slider
                                min={1975}
                                max={2015}
                                value={value}
                                onChangeStart={this.handleChangeStart}
                                onChange={this.handleChange}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        </div>
                    </div>
                </div>
                <Tabs>
                    <TabList style={{textAlign: 'center', color: 'white'}}>
                        <Tab>Movies<br/> <FaFilm/></Tab>
                        <Tab>Music<br/> <FaMusic/></Tab>
                        <Tab>Video Games<br/> <FaGamepad/></Tab>
                        <Tab>Sports<br/> <FaBasketballBall/></Tab>
                    </TabList>
                    <TabPanel style={{marginLeft: '15px', color: 'white'}}>
                        <MovieList dataList={movieList} />
                    </TabPanel>
                    <TabPanel style={{marginLeft: '15px', color: 'white'}}>
                        <MusicList dataList={musicList} />
                    </TabPanel>
                    <TabPanel style={{marginLeft: '15px', color: 'white'}}>
                        <GamesList dataList={gameList}  />
                    </TabPanel>
                    <TabPanel style={{marginLeft: '15px', color: 'white'}}>
                        <SportList dataList={sportList}  />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}


export default connect((state, props) => {

    return {
        sliderYear: state.sliderYear,
        movieList: state.movieList,
        musicList: state.musicList,
        gameList: state.gameList,
        sportList: state.sportList
    }

})(Homescreen);