//Define action creators for the main redux store

//make the needed imports
import axios from 'axios';
import configData from "../APIDetails/config.json";


//SYNCHRONUS ACTIONS
//change slider year
export function changeSliderYear(sliderYear) {
    return {
        type: "CHANGE_SLIDER_YEAR",
        data: sliderYear
    }
}

//ASYNCRONUS ACTIONS
//fetch all year data using supporting actions per list
export function fetchYearData(sliderYear) {
    return (dispatch) =>  {
        dispatch({type:"FETCH_YEAR_DATA_START"});

        dispatch(fetchMovieData(sliderYear));
        dispatch(fetchMusicData(sliderYear));
        dispatch(fetchGameData(sliderYear));
        dispatch(fetchSportData(sliderYear));

        dispatch({type:"FETCH_YEAR_DATA_END"})

    }
}

//fetch year data supporting actions

export function fetchMovieData(sliderYear) {
    return (dispatch) =>  {
    dispatch({type:"FETCH_MOVIE_DATA"});

    axios.get(configData.API_URL + sliderYear + "/movie")
        .then(resp => {
            dispatch({type:"RECIEVED_MOVIE_DATA_SUCCESS", data: resp});
        })
        .catch((err) => {
            dispatch({type:"RECIEVED_MOVIE_DATA_FALIURE", data: err})
        } );
    }
}

export function fetchMusicData(sliderYear) {
    return (dispatch) =>  {
    dispatch({type:"FETCH_MUSIC_DATA"});

    axios.get(configData.API_URL + sliderYear + "/music")
        .then(resp => {
            dispatch({type:"RECIEVED_MUSIC_DATA_SUCCESS", data: resp});
        })
        .catch((err) => {
            dispatch({type:"RECIEVED_MUSIC_DATA_FALIURE", data: err})
        } );
    }
}

export function fetchGameData(sliderYear) {
    return (dispatch) =>  {
    dispatch({type:"FETCH_GAME_DATA"});

    axios.get(configData.API_URL + sliderYear + "/game")
        .then(resp => {
            dispatch({type:"RECIEVED_GAME_DATA_SUCCESS", data: resp});
        })
        .catch((err) => {
            dispatch({type:"RECIEVED_GAME_DATA_FALIURE", data: err})
        } );
    }
}

export function fetchSportData(sliderYear) {
    return (dispatch) =>  {
    dispatch({type:"FETCH_SPORT_DATA"});

    axios.get(configData.API_URL + sliderYear + "/sport")
        .then(resp => {
            dispatch({type:"RECIEVED_SPORT_DATA_SUCCESS", data: resp});
        })
        .catch((err) => {
            dispatch({type:"RECIEVED_SPORT_DATA_FALIURE", data: err})
        } );
    }
}