import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import './Lists.css'


export default class MusicList extends React.Component {

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        const items = this.props.dataList.sort((a, b) => {
            let fa = a.rank_in_year.replace(/(^\$|,)/g,''),
                fb = b.rank_in_year.replace(/(^\$|,)/g,'');
            return fa - fb;
        });
        if (items) {
            return (
                <TableContainer variant="outlined">
                    <Table className="table">
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell className="tableHeader">Song Title</TableCell>
                                <TableCell className="tableHeader">Artist</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                            <TableRow key={item.uuid}>
                                <TableCell className = "tableCell">{item.title}</TableCell>
                                <TableCell className = "tableCell">{item.artist}</TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        else {
            return null
        }
    }
}
