import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from "./components/Navbar/Navbar";
import Homescreen from "./components/Homescreen";
import { Provider } from 'react-redux';
import store from './stores/ConfigureStore.js';

function App() {
  return (
      <Provider store={store} >
      <div style={{
          backgroundColor: 'black',
          backgroundPosition: "",
          width: '100%',
          height: '100%',
          marginTop: 0,
          zIndex: 1,
          overflow: 'hidden',
      }}>
        <Router>
            <Navbar />
        </Router>
            <Homescreen />
          <div style = {{marginTop: "20px",marginBottom: "20px",textAlign:"center"}}><a href={"http://www.luke-hickman.com"} style={{color:"darkgray",opacity:".30"}}>© 2022 Luke Hickman</a></div>
      </div>
      </Provider>
  );
}

export default App;
