import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

var defaultState = {
    sliderYear: 1997,
    movieList: [],
    musicList: [],
    gameList: [],
    sportList: []
};

function main(state = defaultState, action) {
    switch (action.type) {
        case ("CHANGE_SLIDER_YEAR"):
            return {
                ...state,
                sliderYear: action.data
            }
        case ("RECIEVED_MOVIE_DATA_SUCCESS"):
            return {
                ...state,
                movieList: action.data.data.items
            }
        case ("RECIEVED_MUSIC_DATA_SUCCESS"):
            return {
                ...state,
                musicList: action.data.data.items
            }
        case ("RECIEVED_GAME_DATA_SUCCESS"):
            return {
                ...state,
                gameList: action.data.data.items
            }
        case ("RECIEVED_SPORT_DATA_SUCCESS"):
            return {
                ...state,
                sportList: action.data.data.items
            }
        default:
            return state;
    }
}

var logger = createLogger({
    collapsed: true
})

var store = createStore(
    main,
    applyMiddleware(thunk, logger)
    );

export default store;