import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

export default class MovieList extends React.Component {


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        const items = this.props.dataList.sort((a, b) => {
            let fa = a.worldwide_gross.replace(/(^\$|,)/g,''),
                fb = b.worldwide_gross.replace(/(^\$|,)/g,'');
                return fb - fa;
        });
        if (items) {
            return (
                <TableContainer variant="outlined">
                    <Table className="table">
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell className="tableHeader">Title</TableCell>
                                <TableCell className="tableHeader">Genre</TableCell>
                                <TableCell className="tableHeader">Studio</TableCell>
                                <TableCell className="tableHeader">IMDB</TableCell>
                                <TableCell className="tableHeader">Box Office</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.uuid}>
                                    <TableCell className = "tableCell">{item.title}</TableCell>
                                    <TableCell className = "tableCell">{item.Main_Genre}</TableCell>
                                    <TableCell className = "tableCell">{item.studio}</TableCell>
                                    <TableCell className = "tableCell">{item.imdb_rating}</TableCell>
                                    <TableCell className = "tableCell">{item.worldwide_gross}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        else {
            return null
        }
    }
}
